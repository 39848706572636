import React from 'react';
import './CircleSpinner.css';

const CirlceSpinner = ({ backgroundColor, color }) => {
    return (
        <div className="circle-spinner" style={{
            border: `4px solid ${backgroundColor ?? "rgba(255, 255, 255, 0.3)"}`, borderTop: `4px solid ${color ?? "rgba(255, 255, 255)"}`
        }} ></ div>
    );
};

export default CirlceSpinner;