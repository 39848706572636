import React from 'react';
import './LandingPage.css';
import BrandsWeCalled from '../../components/MarketingSections/BrandsWeCalled/BrandsWeCalled';
import FooterSection from '../../components/FooterSection/FooterSection';
import HowItWorks from '../../components/MarketingSections/HowItWorks/HowItWorks';
import FAQSection from '../../components/MarketingSections/FAQSection/FAQSection';
import ContactUsSection from '../../components/MarketingSections/ContactUsSection/ContactUsSection';
import MainWaitlist from '../../components/MarketingSections/MainWaitlist/MainWaitlist';
import Header from '../../components/Header/Header';

const LandingPage = ({ baseURL }) => {
    return (
        <div className="landing-page">
            <Header environment={baseURL?.includes('dev') ? 'dev' : 'prod'} respectUser={false} showLoginButton={true} />
            <div className="scrollable-container">
                <div id="main">
                </div>
                <MainWaitlist />
                <div id="brands">
                    <BrandsWeCalled />
                </div>
                <div id="how-it-works">
                    <HowItWorks />
                </div>
                <div id="faq">
                    <FAQSection />
                </div>
                <div id="contact-us">
                    <ContactUsSection />
                </div>
                <FooterSection />
            </div>
        </div>
    );
};

export default LandingPage;
