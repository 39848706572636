import React from 'react';
import './ContactUsSection.css';

const ContactUsSection = () => {
    return (
        <div className="contact-us-container">
            <h3>Didn't find an answer to your question? Get in touch with us</h3>
            <p>
                Is there something you're uncertain about? Reach out to us via phone or email
            </p>
            <a href="tel:+18556944150" className="phone-link">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="check-icon"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="12" cy="12" r="10" />
                    <path d="M9 12l2 2 4-4" />
                </svg>
                1-855-694-4150

                <a href="mailto:jacob@tagin.ai" className="phone-link">
                    <label style={{ color: '#6B7280' }}>•</label>
                    jacob@tagin.ai
                </a>
            </a>
        </div>
    );
};

export default ContactUsSection;
