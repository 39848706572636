import React, { useState } from 'react';
import './ProfileEditPersonalInformation.css';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';
import { useApi } from '../../utils/apiContext';
import { useNotifications } from '../../components/Alert/Notification/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { useRoute } from '../../utils/RouteContext';

const ProfileEditPersonalInformation = ({ isOnboarding, user }) => {
    const { updateUserProfile } = useApi();
    const { addNotification } = useNotifications();
    const navigate = useNavigate();
    const { determineRoute, RouteEnum } = useRoute();

    const stateOptions = [
        { label: 'Alabama', value: 'AL' },
        { label: 'Alaska', value: 'AK' },
        { label: 'Arizona', value: 'AZ' },
        { label: 'Arkansas', value: 'AR' },
        { label: 'California', value: 'CA' },
        { label: 'Colorado', value: 'CO' },
        { label: 'Connecticut', value: 'CT' },
        { label: 'Delaware', value: 'DE' },
        { label: 'Florida', value: 'FL' },
        { label: 'Georgia', value: 'GA' },
        { label: 'Hawaii', value: 'HI' },
        { label: 'Idaho', value: 'ID' },
        { label: 'Illinois', value: 'IL' },
        { label: 'Indiana', value: 'IN' },
        { label: 'Iowa', value: 'IA' },
        { label: 'Kansas', value: 'KS' },
        { label: 'Kentucky', value: 'KY' },
        { label: 'Louisiana', value: 'LA' },
        { label: 'Maine', value: 'ME' },
        { label: 'Maryland', value: 'MD' },
        { label: 'Massachusetts', value: 'MA' },
        { label: 'Michigan', value: 'MI' },
        { label: 'Minnesota', value: 'MN' },
        { label: 'Mississippi', value: 'MS' },
        { label: 'Missouri', value: 'MO' },
        { label: 'Montana', value: 'MT' },
        { label: 'Nebraska', value: 'NE' },
        { label: 'Nevada', value: 'NV' },
        { label: 'New Hampshire', value: 'NH' },
        { label: 'New Jersey', value: 'NJ' },
        { label: 'New Mexico', value: 'NM' },
        { label: 'New York', value: 'NY' },
        { label: 'North Carolina', value: 'NC' },
        { label: 'North Dakota', value: 'ND' },
        { label: 'Ohio', value: 'OH' },
        { label: 'Oklahoma', value: 'OK' },
        { label: 'Oregon', value: 'OR' },
        { label: 'Pennsylvania', value: 'PA' },
        { label: 'Rhode Island', value: 'RI' },
        { label: 'South Carolina', value: 'SC' },
        { label: 'South Dakota', value: 'SD' },
        { label: 'Tennessee', value: 'TN' },
        { label: 'Texas', value: 'TX' },
        { label: 'Utah', value: 'UT' },
        { label: 'Vermont', value: 'VT' },
        { label: 'Virginia', value: 'VA' },
        { label: 'Washington', value: 'WA' },
        { label: 'West Virginia', value: 'WV' },
        { label: 'Wisconsin', value: 'WI' },
        { label: 'Wyoming', value: 'WY' },
    ];

    const monthOptions = [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
    ];

    const dayOptions = [
        { label: '1', value: '01' },
        { label: '2', value: '02' },
        { label: '3', value: '03' },
        { label: '4', value: '04' },
        { label: '5', value: '05' },
        { label: '6', value: '06' },
        { label: '7', value: '07' },
        { label: '8', value: '08' },
        { label: '9', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
        { label: '25', value: '25' },
        { label: '26', value: '26' },
        { label: '27', value: '27' },
        { label: '28', value: '28' },
        { label: '29', value: '29' },
        { label: '30', value: '30' },
        { label: '31', value: '31' },
    ];

    const yearOptions = [
        { label: '1900', value: '1900' },
        { label: '1901', value: '1901' },
        { label: '1902', value: '1902' },
        { label: '1903', value: '1903' },
        { label: '1904', value: '1904' },
        { label: '1905', value: '1905' },
        { label: '1906', value: '1906' },
        { label: '1907', value: '1907' },
        { label: '1908', value: '1908' },
        { label: '1909', value: '1909' },
        { label: '1910', value: '1910' },
        { label: '1911', value: '1911' },
        { label: '1912', value: '1912' },
        { label: '1913', value: '1913' },
        { label: '1914', value: '1914' },
        { label: '1915', value: '1915' },
        { label: '1916', value: '1916' },
        { label: '1917', value: '1917' },
        { label: '1918', value: '1918' },
        { label: '1919', value: '1919' },
        { label: '1920', value: '1920' },
        { label: '1921', value: '1921' },
        { label: '1922', value: '1922' },
        { label: '1923', value: '1923' },
        { label: '1924', value: '1924' },
        { label: '1925', value: '1925' },
        { label: '1926', value: '1926' },
        { label: '1927', value: '1927' },
        { label: '1928', value: '1928' },
        { label: '1929', value: '1929' },
        { label: '1930', value: '1930' },
        { label: '1931', value: '1931' },
        { label: '1932', value: '1932' },
        { label: '1933', value: '1933' },
        { label: '1934', value: '1934' },
        { label: '1935', value: '1935' },
        { label: '1936', value: '1936' },
        { label: '1937', value: '1937' },
        { label: '1938', value: '1938' },
        { label: '1939', value: '1939' },
        { label: '1940', value: '1940' },
        { label: '1941', value: '1941' },
        { label: '1942', value: '1942' },
        { label: '1943', value: '1943' },
        { label: '1944', value: '1944' },
        { label: '1945', value: '1945' },
        { label: '1946', value: '1946' },
        { label: '1947', value: '1947' },
        { label: '1948', value: '1948' },
        { label: '1949', value: '1949' },
        { label: '1950', value: '1950' },
        { label: '1951', value: '1951' },
        { label: '1952', value: '1952' },
        { label: '1953', value: '1953' },
        { label: '1954', value: '1954' },
        { label: '1955', value: '1955' },
        { label: '1956', value: '1956' },
        { label: '1957', value: '1957' },
        { label: '1958', value: '1958' },
        { label: '1959', value: '1959' },
        { label: '1960', value: '1960' },
        { label: '1961', value: '1961' },
        { label: '1962', value: '1962' },
        { label: '1963', value: '1963' },
        { label: '1964', value: '1964' },
        { label: '1965', value: '1965' },
        { label: '1966', value: '1966' },
        { label: '1967', value: '1967' },
        { label: '1968', value: '1968' },
        { label: '1969', value: '1969' },
        { label: '1970', value: '1970' },
        { label: '1971', value: '1971' },
        { label: '1972', value: '1972' },
        { label: '1973', value: '1973' },
        { label: '1974', value: '1974' },
        { label: '1975', value: '1975' },
        { label: '1976', value: '1976' },
        { label: '1977', value: '1977' },
        { label: '1978', value: '1978' },
        { label: '1979', value: '1979' },
        { label: '1980', value: '1980' },
        { label: '1981', value: '1981' },
        { label: '1982', value: '1982' },
        { label: '1983', value: '1983' },
        { label: '1984', value: '1984' },
        { label: '1985', value: '1985' },
        { label: '1986', value: '1986' },
        { label: '1987', value: '1987' },
        { label: '1988', value: '1988' },
        { label: '1989', value: '1989' },
        { label: '1990', value: '1990' },
        { label: '1991', value: '1991' },
        { label: '1992', value: '1992' },
        { label: '1993', value: '1993' },
        { label: '1994', value: '1994' },
        { label: '1995', value: '1995' },
        { label: '1996', value: '1996' },
        { label: '1997', value: '1997' },
        { label: '1998', value: '1998' },
        { label: '1999', value: '1999' },
        { label: '2000', value: '2000' },
        { label: '2001', value: '2001' },
        { label: '2002', value: '2002' },
        { label: '2003', value: '2003' },
        { label: '2004', value: '2004' },
        { label: '2005', value: '2005' },
        { label: '2006', value: '2006' },
        { label: '2007', value: '2007' },
        { label: '2008', value: '2008' },
        { label: '2009', value: '2009' },
        { label: '2010', value: '2010' },
        { label: '2011', value: '2011' },
        { label: '2012', value: '2012' },
        { label: '2013', value: '2013' },
        { label: '2014', value: '2014' },
        { label: '2015', value: '2015' },
        { label: '2016', value: '2016' },
        { label: '2017', value: '2017' },
        { label: '2018', value: '2018' },
        { label: '2019', value: '2019' },
        { label: '2020', value: '2020' },
        { label: '2021', value: '2021' },
        { label: '2022', value: '2022' },
        { label: '2023', value: '2023' },
        { label: '2024', value: '2024' },
    ];

    const formatPhoneNumber = (value) => {
        if (!value) return value;
        // Remove all non-digits from the value
        const phoneNumber = value.replace(/[^\d]/g, "");

        // Format the phone number
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;

        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handleZipCodeInputChange = (e) => {
        const { name, value } = e.target;
        // Allow only numbers and limit to 5 characters
        if (/^\d{0,5}$/.test(value)) {
            setForm({ ...form, [name]: value });
        }
    };

    const handleDateOfBirthChange = (e) => {
        const { name, value } = e.target;
        const cleanedInput = value.replace(/\D/g, '');

        // Extract month, day, and year components
        const month = cleanedInput.slice(0, 2);
        const day = cleanedInput.slice(2, 4);
        const year = cleanedInput.slice(4, 8);

        // Build formatted date string
        let formattedDOB = month;
        if (day) formattedDOB += `/${day}`;
        if (year) formattedDOB += `/${year}`;

        setForm({ ...form, [name]: formattedDOB });
    };

    const [form, setForm] = useState({
        firstName: user.full_name ? user.full_name.split(" ")[0] : "",
        lastName: user.full_name ? user.full_name.split(" ").slice(1).join(" ") : "",
        email: user.email ? user.email : "",
        phoneNumber: user.phone_number ? formatPhoneNumber(user.phone_number) : "",
        dobMonth: user.date_of_birth ? user.date_of_birth.split("/")[0] : "",
        dobDay: user.date_of_birth ? user.date_of_birth.split("/")[1] : "",
        dobYear: user.date_of_birth ? user.date_of_birth.split("/")[2] : "",
        addressLine1: user.address ? user.address.address_line_1 : "",
        addressLine2: user.address ? user.address.address_line_2 : "",
        city: user.address ? user.address.city : "",
        state: user.address ? user.address.state : "AL",
        zipCode: user.address ? user.address.zip_code : "",
    });

    const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phoneNumber') {
            const formattedPhoneNumber = formatPhoneNumber(value);
            setForm({ ...form, [name]: formattedPhoneNumber });
        } else if (name === 'zipCode') {
            handleZipCodeInputChange(e);
        } else if (name === 'dateOfBirth') {
            handleDateOfBirthChange(e);
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const handleSave = async () => {
        let jsonBody = {}

        if (form.firstName || form.lastName) {
            let fullName = ''
            if (form.firstName) {
                fullName += form.firstName;
            }

            if (form.lastName) {
                fullName += ' ' + form.lastName;
            }

            if (fullName) {
                jsonBody['full_name'] = fullName;
            }
        }

        if (form.email) {
            jsonBody['email'] = form.email;
        }

        if (form.phoneNumber) {
            jsonBody['phone_number'] = form.phoneNumber;
        }

        if (form.dobMonth && form.dobDay && form.dobYear && form.dobYear !== "1900") {
            jsonBody['date_of_birth'] = form.dobMonth + '/' + form.dobDay + '/' + form.dobYear;
        }

        if (form.addressLine1 && form.city && form.state && form.zipCode) {
            let addressJson = {}
            addressJson['address_line_1'] = form.addressLine1;
            addressJson['address_line_2'] = form.addressLine2;
            addressJson['city'] = form.city;
            addressJson['state'] = form.state;
            addressJson['zip_code'] = form.zipCode;
            jsonBody['address'] = addressJson;
        }

        setSaveButtonIsLoading(true);

        try {
            const data = await updateUserProfile(jsonBody);

            if (data.error) {
                console.log(data.error);
                addNotification("Couldn't complete your request, please try again later.", "error");
            }

            if (data.status) {
                if (isOnboarding) {
                    const path = determineRoute(RouteEnum.DASHBOARD);
                    navigate(path);
                } else {
                    addNotification("Saved!", "success");
                }
            }
        } catch (error) {
            console.error('Error:', error);
            addNotification("Failed", "error");
        } finally {
            setSaveButtonIsLoading(false);
        }
    }

    return (
        <div className='profile-edit-personal-information-container'>
            <h4>Personal Information</h4>
            <div className="profile-edit-personal-information-form-horizontal-group">
                <div className="profile-edit-personal-information-form-input-section">
                    <label>First Name</label>
                    <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={form.firstName}
                        onChange={handleChange}
                    />
                </div>
                <div className="profile-edit-personal-information-form-input-section">
                    <label>Last Name</label>
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={form.lastName}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="profile-edit-personal-information-form-horizontal-group">
                <div className="profile-edit-personal-information-form-input-section">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={form.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="profile-edit-personal-information-form-input-section">
                    <label>Phone Number</label>
                    <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        value={form.phoneNumber}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="profile-edit-personal-information-form-horizontal-group">
                <div className="profile-edit-personal-information-form-input-section">
                    <label>Month</label>
                    <div className="state-select-container">
                        <select
                            name="dobMonth"
                            value={form.dobMonth}
                            onChange={handleChange}
                        >
                            {monthOptions.map((month) => (
                                <option key={month.value} value={month.value}>
                                    {month.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="profile-edit-personal-information-form-input-section">
                    <label>Day</label>
                    <div className="state-select-container">
                        <select
                            name="dobDay"
                            value={form.dobDay}
                            onChange={handleChange}
                        >
                            {dayOptions.map((day) => (
                                <option key={day.value} value={day.value}>
                                    {day.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="profile-edit-personal-information-form-input-section">
                    <label>Year</label>
                    <div className="state-select-container">
                        <select
                            name="dobYear"
                            value={form.dobYear}
                            onChange={handleChange}
                        >
                            {yearOptions.map((year) => (
                                <option key={year.value} value={year.value}>
                                    {year.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="profile-edit-personal-information-form-input-section">
                <label>Address Line 1</label>
                <input
                    type="text"
                    name="addressLine1"
                    placeholder="Address Line 1"
                    value={form.addressLine1}
                    onChange={handleChange}
                />
            </div>
            <div className="profile-edit-personal-information-form-input-section">
                <label>Address Line 2</label>
                <input
                    type="text"
                    name="addressLine2"
                    placeholder="Address Line 2"
                    value={form.addressLine2}
                    onChange={handleChange}
                />
            </div>
            <div className="profile-edit-personal-information-form-horizontal-group">
                <div className="profile-edit-personal-information-form-input-section">
                    <label>City</label>
                    <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={form.city}
                        onChange={handleChange}
                    />
                </div>
                <div className="profile-edit-personal-information-form-input-section">
                    <label>State</label>
                    <div className="state-select-container">
                        <select
                            name="state"
                            value={form.state}
                            onChange={handleChange}
                        >
                            {stateOptions.map((state) => (
                                <option key={state.value} value={state.value}>
                                    {state.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="profile-edit-personal-information-form-input-section">
                    <label>Zip code</label>
                    <input
                        type="text"
                        name="zipCode"
                        placeholder="Zip Code"
                        value={form.zipCode}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <ButtonWithLoading
                onClick={handleSave}
                loading={saveButtonIsLoading}
            >
                {isOnboarding ? "Save & Continue" : "Save"}
            </ButtonWithLoading>
        </div >
    );
}

export default ProfileEditPersonalInformation;