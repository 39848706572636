import React from 'react';
import InfoCard from './HowItWorksInfoCard';
import './HowItWorks.css';
import icon1 from './infoCardIconChat.png';
import icon2 from './infoCardIconPhone.png';
import icon3 from './infoCardIconClipboard.png';

const HowItWorks = () => {
    const steps = [
        {
            title: 'Share Your Need',
            description:
                'Tell us what you need help with. Through a brief conversation, we\'ll gather all the essential details to handle your request effectively.',
            icon: icon1,
        },
        {
            title: 'We Handle the Calls',
            description:
                'We communicate directly with healthcare providers, handling all necessary calls and follow-ups until your task is complete.',
            icon: icon2,
        },
        {
            title: 'Stay Informed',
            description:
                'Receive clear updates throughout the process and a detailed summary of actions taken, next steps, and resolution status.',
            icon: icon3,
        },
    ];

    return (
        <section className="how-it-works">
            <div className="how-it-works-header">
                <p className="how-it-works-title">How We Handle Your Healthcare Calls</p>
                <p className="how-it-works-subtitle">To give you certainty in a complex system</p>
                <p className="how-it-works-description">
                    You explain the task, AI handles the phone call, we deliver a comprehensive summary.
                    <br />
                    Simple and efficient
                </p>
            </div>

            <div className="how-it-works-grid">
                {steps.map((step, index) => (
                    <InfoCard
                        key={index}
                        icon={step.icon}
                        title={step.title}
                        description={step.description}
                    />
                ))}
            </div>
        </section>
    );
};

export default HowItWorks;
