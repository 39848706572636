import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from React Router
import './NotificationStack.css';

const NotificationContext = createContext();

export const useNotifications = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const location = useLocation(); // Access the current location (route)

    const addNotification = (message, type) => {
        const newNotification = { message, type, id: Date.now(), isVisible: false };

        setNotifications((prev) => {
            const updated = [newNotification, ...prev];
            if (updated.length > 3) {
                updated.pop(); // Remove the oldest notification if more than 3
            }
            return updated;
        });

        // Add the 'show' class after a short delay to trigger the enter animation
        setTimeout(() => {
            setNotifications((prev) =>
                prev.map((notification) =>
                    notification.id === newNotification.id ? { ...notification, isVisible: true } : notification
                )
            );
        }, 100); // Small delay to ensure animation works

        // Set a timer to start hiding the notification after 4 seconds
        setTimeout(() => hideNotification(newNotification.id), 4000);

        // Fully remove the notification after 5 seconds (allowing time for the hide animation)
        setTimeout(() => dismissNotification(newNotification.id), 5000);
    };

    const hideNotification = (id) => {
        setNotifications((prev) =>
            prev.map((notification) =>
                notification.id === id ? { ...notification, isVisible: false } : notification
            )
        );
    };

    const dismissNotification = (id) => {
        // First, trigger the hiding animation
        hideNotification(id);

        // Then, after the animation is done (0.5s), remove the notification from state
        setTimeout(() => {
            setNotifications((prev) => prev.filter(notification => notification.id !== id));
        }, 500); // Match the CSS transition duration
    };

    // Clear notifications when navigating to a new screen
    useEffect(() => {
        setNotifications([]);
    }, [location]);

    return (
        <NotificationContext.Provider value={{ addNotification }}>
            <div className="notification-stack">
                {notifications.map(({ id, message, type, isVisible }) => (
                    <div
                        key={id}
                        className={`notification ${type} ${isVisible ? 'show' : 'hide'}`}
                    >
                        {message}
                        <button onClick={() => dismissNotification(id)} style={{ marginLeft: '10px' }}>✖</button>
                    </div>
                ))}
            </div>
            {children}
        </NotificationContext.Provider>
    );
};
