import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import "../screens/Dashboard/Dashboard.css";
import { useRoute } from './RouteContext';

const ProtectedRoute = ({ children }) => {
    const { user, loading } = useAuth();
    const navigate = useNavigate();
    const { determineRoute, RouteEnum } = useRoute();

    useEffect(() => {
        if (!loading && !user) {
            const path = determineRoute(RouteEnum.HOME);
            navigate(path);
        }
    }, [user, loading, navigate]);

    if (loading) {
        return <div className='main-container'>
            {/* <div className='spinner-background'>
                <div className='spinner'></div>
            </div> */}
        </div>;
    }

    return user ? children : null;
};

export default ProtectedRoute;