import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfigProd = {
    apiKey: "AIzaSyBFcougAcTGhhNh79pfd3FJ6LZvb_6NK6E",
    authDomain: "marlo-ai-prod.firebaseapp.com",
    projectId: "marlo-ai-prod",
    storageBucket: "marlo-ai-prod.appspot.com",
    messagingSenderId: "547042067077",
    appId: "1:547042067077:web:32d0e429cfe2b85846dfec",
    measurementId: "G-25TXHHYVSP"
};

const firebaseConfigDev = {
    apiKey: "AIzaSyBGO2IEDFXMO4ULclXg0AVECUUzcsPB1tw",
    authDomain: "marlo-ai-dev.firebaseapp.com",
    projectId: "marlo-ai-dev",
    storageBucket: "marlo-ai-dev.appspot.com",
    messagingSenderId: "1057741148240",
    appId: "1:1057741148240:web:a115eff2a97c0d91bc4155",
    measurementId: "G-C5YJ3GDVXT"
};

// Initialize Firebase dynamically based on environment
export const initializeFirebaseApp = (isDev) => {
    const firebaseConfig = isDev ? firebaseConfigDev : firebaseConfigProd;

    // Check if Firebase is already initialized
    const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

    // Get Auth and Provider
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    return { auth, provider };
};