import React from 'react';
import './FooterSection.css';
import XIcon from './assets/x.png';
import FacebookIcon from './assets/facebook.png';
import LinkedInIcon from './assets/linkedin.png';

const FooterSection = () => {
    return (
        <section className="footer-section">
            <footer className="footer">
                <div className="footer-left">
                    <p>© 2024 Tagin, Inc. All rights reserved.</p>
                </div>
                <div className="footer-right">
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <img src={XIcon} alt="X" className="footer-icon" />
                    </a>
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                        <img src={FacebookIcon} alt="Facebook" className="footer-icon" />
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        <img src={LinkedInIcon} alt="LinkedIn" className="footer-icon" />
                    </a>
                </div>
            </footer>
        </section>
    );
};

export default FooterSection;
