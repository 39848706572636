import React, { useEffect, useState, useRef } from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../../utils/FirebaseContext';
import './RedeemInviteCode.css';
import { useAuth } from '../../utils/AuthContext';
import { useApi } from '../../utils/apiContext';
import { useNotifications } from '../../components/Alert/Notification/NotificationContext';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';
import Header from '../../components/Header/Header';
import CirlceSpinner from '../../components/Spinners/CircleSpinner/CirlceSpinner';
import { useRoute } from '../../utils/RouteContext';

const RedeemInviteCode = ({ baseURL }) => {
    const navigate = useNavigate();
    const { determineRoute, RouteEnum } = useRoute();
    const { user } = useAuth();
    const { auth } = useFirebase();
    const { redeemInviteCode } = useApi();
    const [googleSignInButtonIsLoading, setGoogleSignInButtonIsLoading] = useState(false);
    const [isValidatingInviteCode, setIsValidatingInviteCode] = useState(false);
    const [inviteCodeStatus, setInviteCodeStatus] = useState("");
    const { addNotification } = useNotifications();
    const [makingRequest, setMakingRequest] = useState(false);
    const [inviteCode, setInviteCode] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        const url = window.location.href;
        const queryParams = new URLSearchParams(url.split('?')[1]);
        const inviteCode = queryParams.get('invite_code');
        if (inviteCode) {
            setInviteCode(inviteCode);
        }
    }, []);

    useEffect(() => {
        if (makingRequest === true) {
            console.log('MAKING REUQEST');
            return;
        }

        if (user) {
            const route = determineRoute(RouteEnum.DASHBOARD);
            navigate(route);
        } else {
            setGoogleSignInButtonIsLoading(false);
        }
    }, [user, navigate, RouteEnum, makingRequest]);

    const handleInviteCodeChange = (event) => {
        const { value } = event.target;
        setInviteCode(value);
        setInviteCodeStatus('');
    };

    const [debouncedValue, setDebouncedValue] = useState('');

    useEffect(() => {
        if (inviteCode.length >= 3) {
            const handler = setTimeout(() => {
                setDebouncedValue(inviteCode);
            }, 300);

            return () => {
                clearTimeout(handler);
            };
        }
    }, [inviteCode]);

    useEffect(() => {
        if (debouncedValue) {
            handleValidateInviteCode();
        }
    }, [debouncedValue]);

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();

        try {
            setMakingRequest(true);
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            setGoogleSignInButtonIsLoading(true);
            const idToken = await user.getIdToken();
            let upperCaseInviteCode = inviteCode.toUpperCase();

            const response = await fetch(`${baseURL}/auth/google`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: idToken,
                    invite_code_redeemed: upperCaseInviteCode
                }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('sessionToken', idToken);
                const path = determineRoute((data.new_user && data.new_user === true) ? RouteEnum.ONBOARDING : RouteEnum.DASHBOARD);
                navigate(path);
            } else {
                addNotification('Failed to authenticate', 'error');
            }

        } catch (error) {
            if (error.code === 'auth/popup-closed-by-user') {
                console.warn('Google Sign-In popup was closed by the user.');
            } else {
                addNotification('Google Sign-In failed: ' + error.message, 'error');
            }
        }

        setGoogleSignInButtonIsLoading(false);
        setMakingRequest(false);
    };

    const handleValidateInviteCode = async () => {
        setIsValidatingInviteCode(true);
        let upperCaseInviteCode = inviteCode.toUpperCase();

        try {
            const data = await redeemInviteCode(upperCaseInviteCode);
            if (data.status) {
                setInviteCodeStatus('valid');
                setIsValidatingInviteCode(false);
                return true;
            } else {
                setInviteCodeStatus('invalid');
                addNotification(data.error === "INVITE_CODE_IS_NO_LONGER_VALID" ? 'Invite code is no longer valid' : 'Invalid invite code', 'error');
                setIsValidatingInviteCode(false);
                return false;
            }
        } catch (error) {
            addNotification('Unknown error occurred. Please try again later.', 'error');
            setIsValidatingInviteCode(false);
            setInviteCodeStatus('');
            return false;
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [inviteCodeStatus]);

    return (
        <>
            <Header environment={baseURL?.includes('dev') ? 'dev' : 'prod'} logoOnly={true} />
            <div className="redeem-invite-code-container">
                <h4>Enter your invite code to continue</h4>
                <div className='redeem-invite-code-action-container'>
                    <div className={`redeem-invite-code-input-container${inviteCodeStatus === "" ? "" : "-" + inviteCodeStatus}`}>
                        <input
                            type="text"
                            className={'redeem-invite-code-input'}
                            value={inviteCode}
                            onChange={(e) => handleInviteCodeChange(e)}
                            placeholder="Enter invite code"
                            disabled={inviteCodeStatus === 'valid' || isValidatingInviteCode}
                            ref={inputRef}
                        />
                        {inviteCodeStatus === 'invalid' ? (
                            <div style={{ width: '24px', height: '24px', borderRadius: '50%', backgroundColor: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: 'white', fontSize: '16px' }}>✕</span>
                            </div>
                        ) : inviteCodeStatus === 'valid' ? (
                            <div style={{ width: '24px', height: '24px', borderRadius: '50%', backgroundColor: '#2ECC71', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: 'white', fontSize: '16px' }}>✔</span>
                            </div>
                        ) : isValidatingInviteCode ? (<CirlceSpinner color='#D3D3D3' style={{ width: '24px', height: '24px' }} ></CirlceSpinner>) : (
                            <div style={{ opacity: 0, width: '24px', height: '24px', borderRadius: '50%', backgroundColor: '#2ECC71', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: 'white', fontSize: '16px' }}>✔</span>
                            </div>
                        )}
                    </div>
                    <ButtonWithLoading
                        onClick={handleGoogleSignIn}
                        loading={googleSignInButtonIsLoading}
                        disabled={inviteCodeStatus !== 'valid'}
                    >
                        Sign In With Google
                    </ButtonWithLoading>
                </div>
            </div >
        </>
    );
};

export default RedeemInviteCode;