import React from 'react';
import './BrandsWeCalled.css';
import wallgreens from './brand-images/wallgreens.png';
import welldyne from './brand-images/welldyne.png';
import accredo from './brand-images/accredo.png';
import rightway from './brand-images/rightway.png';
import optum from './brand-images/optumRX.png';
import cvs from './brand-images/cvs.png';

const BrandsWeCalled = () => {
    const companies = [
        { name: 'Walgreens', logo: wallgreens },
        { name: 'Welldyne', logo: welldyne },
        { name: 'Accredo', logo: accredo },
        { name: 'Rightway', logo: rightway },
        { name: 'Optum Rx', logo: optum },
        { name: 'CVS', logo: cvs },
    ];

    return (
        <div className="brands-container">
            <h2>Successfully completed tasks that involved calling</h2>
            <div className="brands-grid">
                {companies.map((company, index) => (
                    <div key={index} className="brands-grid-item">
                        <img src={company.logo} alt={company.name} />
                        {/* <p>{company.name}</p> */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BrandsWeCalled;
