import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRoute } from "./utils/RouteContext";

const PageNotFoundView = () => {
    const navigate = useNavigate()
    const { determineRoute, RouteEnum } = useRoute()

    useEffect(() => {
        const path = determineRoute(RouteEnum.HOME);
        navigate(path);
    }, [])

    return (
        <h1></h1>
    )
}

export default PageNotFoundView