import React from 'react';
import './MainWaitlist.css';
import MarqueeTextSection from './Marquee/MarqueeTextSection';
import WaitlistInput from './WaitlistInput/WaitlistInput';
const MainWaitlist = ({ baseURL }) => {
    return (
        <div className="main-waitlist-container">
            <div className="main-waitlist-container-titles">
                <h2>Hand Off Your Healthcare Hassles</h2>
                <h4>We manage all healthcare-related calls and communications, so you can focus on what matters</h4>
            </div>
            <MarqueeTextSection />
            {/* <WaitlistInput baseURL={baseURL} /> */}
        </div>
    );
};

export default MainWaitlist;