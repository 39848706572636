import React, { useState, useEffect, useRef } from 'react';
import './ChatWindow.css';
import { useAuth } from '../../utils/AuthContext';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';

const ChatWindow = ({ baseURL, taskId }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const messagesEndRef = useRef(null);
    const socketRef = useRef(null);
    const { reauthenticate, sessionToken } = useAuth();
    const [userEngaged, setUserEngaged] = useState(false);

    useEffect(() => {
        if (taskId) {
            // Initialize WebSocket connection
            socketRef.current = new WebSocket(baseURL?.includes("dev") ? 'wss://dev.tagin.ai/chat' : 'wss://prod.tagin.ai/chat');

            socketRef.current.onopen = () => {
                console.log("WebSocket connection established.");
                // Send a connection event with the taskId when the WebSocket opens
                socketRef.current.send(JSON.stringify({
                    event: 'connected',
                    user_session_token: sessionToken,
                    task_id: taskId,
                }));
            };

            // Handle incoming messages from the WebSocket server
            socketRef.current.onmessage = async (event) => {
                try {
                    const messageContent = JSON.parse(event.data);
                    if (messageContent.event === 'message') {
                        setMessages((prevMessages) => {
                            if (prevMessages.length === 0) {
                                setIsConnected(true);
                            }
                            return [...prevMessages, { text: messageContent.message, incoming: true, role: 'assistant' }];
                        });
                        setIsProcessing(false); // Reset processing state when a message is received
                    } else if (messageContent.event === 'continue_chat') {
                        setMessages((prevMessages) => {
                            if (prevMessages.length === 0) {
                                setIsConnected(true);
                            }
                            const newMessages = messageContent.messages
                                .filter((msg, index) => !(index === 0 && msg.role === 'user' && msg.content === 'Hello'))
                                .map(msg => ({
                                    text: msg.content,
                                    incoming: msg.role === 'assistant',
                                    role: msg.role
                                }));
                            return [...prevMessages, ...newMessages];
                        });
                        setIsProcessing(false);
                    } else if (messageContent.event === 'error') {
                        setIsConnected(false);
                        setIsProcessing(false);

                        if (messageContent.error === "token_expired") {
                            // Call reauthentication logic here
                            await reauthenticate();
                            // Try sending the message again after reauthentication
                            if (input.trim()) {
                                const messageData = {
                                    event: 'message',
                                    message: input,
                                    task_id: taskId,
                                };
                                socketRef.current.send(JSON.stringify(messageData));
                                // Add the message to the local state only if it wasn't already added
                                setMessages((prevMessages) => {
                                    const isMessageAlreadyAdded = prevMessages.some(msg => msg.text === input && !msg.incoming);
                                    if (!isMessageAlreadyAdded) {
                                        return [...prevMessages, { text: input, incoming: false, role: 'user' }];
                                    }
                                    return prevMessages;
                                });
                            }
                        } else {
                            console.error("Error received from server:", messageContent.error);
                        }
                    } else if (messageContent.event === 'disconnected') {
                        console.log("Call has disconnected");
                        setIsConnected(false);
                        setIsProcessing(false);
                    }
                } catch (error) {
                    console.error("Error parsing message from server:", error);
                    setIsConnected(false);
                    setIsProcessing(false);
                }
            };

            socketRef.current.onerror = (error) => {
                console.error("WebSocket error:", error);
                setIsConnected(false);
                setIsProcessing(false);
            };

            socketRef.current.onclose = () => {
                console.log("WebSocket connection closed.");
                setIsConnected(false);
                setIsProcessing(false);
            };

            // Cleanup when the component unmounts
            return () => {
                socketRef.current.close();
            };
        }
    }, [taskId]);

    const handleSendMessage = () => {
        if (input.trim()) {
            setIsProcessing(true); // Set processing state when sending a message
            // Send message to WebSocket server
            const messageData = {
                event: 'message',
                message: input,
                task_id: taskId,
            };
            socketRef.current.send(JSON.stringify(messageData));

            // Add the message to the local state
            setMessages((prevMessages) => [...prevMessages, { text: input, incoming: false, role: 'user' }]);
            setInput('');
            setUserEngaged(true); // Mark user as engaged when they send a message
        }
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
        setUserEngaged(true); // Mark user as engaged when they type
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    useEffect(() => {
        // Scroll to the bottom when a new message is added, only if user is engaged
        if (messagesEndRef.current && userEngaged) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, userEngaged]);

    return (
        <div className="chat-window-wrapper">
            <div className="chat-window">
                <div className="chat-messages">
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={`chat-bubble ${message.incoming ? 'incoming' : 'outgoing'}`}
                        >
                            {message.text}
                        </div>
                    ))}
                    {/* Show typing indicator when not connected or after sending a message */}
                    {(isProcessing || !isConnected) && (
                        <div className="chat-bubble incoming typing-indicator">
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <div className="chat-input-container">
                    <input
                        type="text"
                        className="chat-input"
                        placeholder="Type a message..."
                        value={input}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                    />
                    <ButtonWithLoading
                        onClick={handleSendMessage}
                        loading={isProcessing} // Set loading state based on processing or parent loading state
                        disabled={!input.trim() || isProcessing || !isConnected || messages.length === 0} // Disable button if input is empty, processing, not connected, or no messages
                    >
                        Send
                    </ButtonWithLoading>
                </div>
            </div>
        </div>
    );
};

export default ChatWindow;
