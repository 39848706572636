import React, { useState, useRef, useEffect } from 'react';
import './FAQSection.css';

const FAQSection = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const answerRefs = useRef([]); // Keep track of refs for each answer div

    const faqs = [
        {
            question: 'How does Tagin understand my needs?',
            answer: 'Through a simple conversation, we gather the essential details about your healthcare task. We ask clear questions to ensure we have everything needed to handle your request effectively.',
        },
        {
            question: 'What types of healthcare tasks can you handle?',
            answer: 'We can handle any task that requires phone calls within the U.S. healthcare system - from insurance and pharmacy issues to provider communications and billing matters. If it needs a call, we can handle it.',
        },
        {
            question: 'How quickly do you complete tasks?',
            answer: 'Most tasks are completed within a few hours. For more complex situations that may require multiple calls or waiting periods, we keep you updated on progress throughout the process.',
        },
        {
            question: 'How does Tagin make calls on my behalf?',
            answer: 'We use advanced AI technology to make phone calls, speaking naturally with healthcare providers while acting as your authorized representative. The AI handles all communications professionally and effectively.',
        },
        {
            question: 'Can AI really handle complex healthcare conversations?',
            answer: 'Yes. Our AI is specifically designed to navigate healthcare communications. It only shares information that you have explicitly provided through Tagin. If additional information is needed to complete your task, we’ll notify you first and ask you to provide it. The AI understands medical terminology, insurance processes, and how to persist until tasks are resolved. If any situation requires human intervention, our team is ready to assist.',
        },
        {
            question: 'What if the AI encounters an unusual situation?',
            answer: 'If our AI encounters any unexpected scenarios or challenges, we’ll immediately let you know what happened and ask how you’d like to proceed. We ensure you maintain control of the process and can decide the best path forward. Our human team is also available to provide guidance and support for any complex situations.',
        },
        {
            question: 'How do I know what\'s happening with my task?',
            answer: 'You receive clear updates throughout the process. Once complete, you get a detailed summary explaining what was accomplished, any important information gathered, and any next steps if needed.',
        },
        {
            question: 'What if something needs to be corrected?',
            answer: 'If any aspect of your task needs adjustment, simply let us know. We\'ll make additional calls and follow up until everything is resolved to your satisfaction.',
        },
        {
            question: 'How do you ensure my information is secure?',
            answer: 'We maintain strict security protocols and only share the specific information necessary to complete your requested tasks with relevant healthcare providers.',
        },
        {
            question: 'Can you work with any healthcare provider?',
            answer: 'Yes, we can communicate with any healthcare organization in the United States - including insurance companies, pharmacies, medical offices, hospitals, and billing departments.',
        },
        {
            question: 'What happens if my task can\'t be resolved in one call?',
            answer: 'We persist until your task is complete, making multiple calls if necessary and navigating between different organizations to reach a resolution. You\'ll be kept informed throughout the process.',
        },
        {
            question: 'Why use AI for healthcare calls?',
            answer: 'AI allows us to handle calls efficiently and persistently, staying on hold as long as needed and making multiple follow-up calls until your task is resolved. This technology enables us to provide consistent, thorough service while keeping you informed throughout the process.',
        },
        {
            question: 'How does the AI know what to say?',
            answer: 'Through our initial conversation with you, we gather all necessary details about your task. The AI uses this information to communicate effectively with healthcare providers, following standard protocols and procedures while representing your needs accurately.',
        },
        {
            question: 'How much does Tagin cost?',
            answer: 'We\'re currently in beta testing and offering our service at no cost. When we launch fully, we\'ll have transparent, straightforward pricing designed to deliver clear value.',
        },
        {
            question: 'What about urgent medical needs?',
            answer: 'For medical emergencies, please contact emergency services directly. Tagin is designed to handle administrative and communication tasks, not urgent medical care.',
        },
    ];


    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    // Ensure proper height when expanded and collapsed
    useEffect(() => {
        faqs.forEach((_, index) => {
            const answerDiv = answerRefs.current[index];
            if (index === openIndex) {
                answerDiv.style.maxHeight = answerDiv.scrollHeight + 'px';
            } else {
                answerDiv.style.maxHeight = '0px';
            }
        });
    }, [openIndex]);

    return (
        <section className="faq-section">
            <div className="faq-header">
                <h2>Frequently asked questions</h2>
                <p>Quick answers to common questions about our AI healthcare assistant</p>
            </div>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div
                        key={index}
                        className="faq-item"
                        onClick={() => toggleFAQ(index)}
                    >
                        <div className="faq-question">
                            {faq.question}
                            <span className="faq-icon">
                                {openIndex === index ? '−' : '+'}
                            </span>
                        </div>
                        <div
                            className="faq-answer"
                            ref={(el) => (answerRefs.current[index] = el)}
                        >
                            <p>{faq.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default FAQSection;
