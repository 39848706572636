import React from "react";
import "./MarqueeTextSection.css";
import checkIcon from "./images/check.svg";

const MarqueeTextSection = () => {

    return (
        <div className="marquee-text-section">
            <div className="marquee-content">
                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Call the specialty pharmacy about my delayed shipment
                </div>
                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Call and track down lost test results between providers
                </div>
                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Call my insurance about coverage for a new treatment
                </div>

                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Call the specialty pharmacy about my delayed shipment
                </div>
                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Call and track down lost test results between providers
                </div>
                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Call my insurance about coverage for a new treatment
                </div>
            </div>

            <div className="marquee-content">
                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Call to clarify confusing insurance policy details
                </div>
                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Call my insurance to appeal a denied claim
                </div>
                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Get clarification on unexpected medical charges
                </div>

                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Call to clarify confusing insurance policy details
                </div>
                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Call my insurance to appeal a denied claim
                </div>
                <div className="marquee-text-pill">
                    <img src={checkIcon} alt="Check Icon" style={{ marginRight: '6px' }} />
                    Get clarification on unexpected medical charges
                </div>
            </div>
        </div>
    );
};

export default MarqueeTextSection;