import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useFirebase } from './FirebaseContext';
import { useNavigate } from 'react-router-dom';
import { useRoute } from './RouteContext';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sessionToken, setSessionToken] = useState(null);
    const { auth, isInitialized } = useFirebase();
    const navigate = useNavigate();
    const { determineRoute, RouteEnum } = useRoute();

    useEffect(() => {
        if (!isInitialized) return;

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setUser(user);
            if (user) {
                const token = await user.getIdToken();
                setSessionToken(token);
            } else {
                setSessionToken(null);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [auth, isInitialized]);

    const reauthenticate = async () => {
        if (!user) {
            logout();
            return;
        }

        try {
            const token = await user.getIdToken(true);
            setSessionToken(token);
            return token;
        } catch (error) {
            console.error('Reauthentication failed:', error);
            logout();
        }
    };

    const logout = async () => {
        if (!auth) return;
        await auth.signOut();
        setUser(null);
        setSessionToken(null);
        const path = determineRoute(RouteEnum.HOME);
        navigate(path);
    };

    return (
        <AuthContext.Provider value={{ user, loading, logout, sessionToken, setSessionToken, reauthenticate }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);