import React, { useState, useEffect } from 'react';
import './DetailTaskItem.css';
import { useApi } from '../../utils/apiContext';
import { useAuth } from '../../utils/AuthContext';
import { useNotifications } from '../../components/Alert/Notification/NotificationContext';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';
import Header from "../../components/Header/Header";
import { useRoute } from "../../utils/RouteContext";
import { useParams, useNavigate } from 'react-router-dom';
import { useTaskCache } from './TaskCacheContext';
import Spinner from '../../components/Spinners/CircleSpinner/CirlceSpinner';

const DetailTaskItem = ({ baseURL }) => {
    const { sessionToken } = useAuth();
    const { deleteTask, fetchTaskWithCalls } = useApi();
    const { addNotification } = useNotifications();
    const [isLoading, setIsLoading] = useState(true); // Initialize loading to true
    const { determineRoute, RouteEnum } = useRoute();
    const { task_id } = useParams();
    const { taskCache, setTaskCache } = useTaskCache();
    const [task, setTask] = useState(null);
    const [expandedCallID, setExpandedCallID] = useState(null); // State to track expanded call
    const [inProgressCallId, setInProgressCallId] = useState(null);
    const [taskCalls, setTaskCalls] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (taskCache[task_id]) {
            setTask(taskCache[task_id]);
            hanldeSetTaskCalls(taskCache[task_id].calls);
            setIsLoading(false); // Set loading to false if task is cached
            return;
        }

        const fetchTaskDetails = async () => {
            try {
                const taskDetails = await fetchTaskWithCalls(task_id);
                setTask(taskDetails.task);
                setTaskCache(prevCache => ({ ...prevCache, [task_id]: taskDetails.task }));
                hanldeSetTaskCalls(taskDetails.task.calls);
            } catch (error) {
                console.error("Failed to fetch task details:", error);
                addNotification("Failed to fetch task details", "error");
            } finally {
                setIsLoading(false); // Ensure loading is set to false after fetching
            }
        };

        fetchTaskDetails();
    }, [task_id, taskCache, setTaskCache]);

    function hanldeSetTaskCalls(calls) {
        setTaskCalls(calls);
        if (calls.length > 0) {
            const lastCall = calls[calls.length - 1];
            if (lastCall.states.length > 0 && lastCall.states[lastCall.states.length - 1].state === "in_progress") {
                setInProgressCallId(lastCall.call_id);
            }
        }
    }

    useEffect(() => {
        const wsUrl = baseURL?.includes("dev")
            ? 'wss://dev.tagin.ai/call_transcript'
            : 'wss://prod.tagin.ai/call_transcript';

        const socket = new WebSocket(wsUrl);

        socket.onopen = () => {
            console.log('DEEPGRAM AI WebSocket connection established');
            socket.send(JSON.stringify({
                user_session_token: sessionToken,
                call_id: inProgressCallId,
                event: "connected",
                value: "WebSocket connection established"
            }));
        };

        socket.onmessage = async (event) => {
            const data = JSON.parse(event.data);
            if (data?.error === "token_expired") {
                console.log("Token expired");
                // await reauthenticate();
                // socket.send(JSON.stringify({
                //     user_session_token: sessionToken,
                //     event: "reconnected",
                //     value: "WebSocket connection re-established"
                // }));
            } else {
                setTaskCalls(prevCalls => {
                    const newCalls = [...prevCalls];
                    const callIndex = newCalls.findIndex(call => call.call_id === inProgressCallId);
                    if (callIndex !== -1) {
                        newCalls[callIndex].transcript.push({ role: data.role, content: data.content });
                    }
                    return newCalls;
                });
            }
        };

        socket.onerror = (error) => console.error('DEEPGRAM AI WebSocket error:', error);
        socket.onclose = () => console.log('DEEPGRAM AI WebSocket closed');

        return () => socket.close(); // Clean up WebSocket on unmount
    }, [baseURL, sessionToken, inProgressCallId]);

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            await deleteTask(task_id);
            addNotification("Task deleted successfully", "success");
            navigateToDashboard();
            setTaskCache(prevCache => {
                const newCache = { ...prevCache };
                delete newCache[task_id];
                return newCache;
            });
        } catch (error) {
            console.error("Failed to delete task:", error);
            addNotification("Failed to delete task", "error");
        } finally {
            setIsLoading(false);
        }
    };

    const newTaskClicked = () => {
        const route = determineRoute(RouteEnum.NEW_TASK);
        navigate(route);
    };

    const navigateToDashboard = () => {
        const route = determineRoute(RouteEnum.DASHBOARD);
        navigate(route);
    };

    const backgroundColor = '#f44336';
    const hoverColor = '#b22222';

    const toggleCallExpand = (callID) => {
        // Check if there's any selected text
        const selection = window.getSelection();
        if (selection.toString().length > 0) {
            return; // Don't trigger the click if text is selected
        }
        setExpandedCallID(expandedCallID === callID ? null : callID);
    };

    return (
        <>
            <Header
                environment={baseURL?.includes("dev") ? "dev" : "prod"}
                respectUser={true}
                onNewTask={newTaskClicked}
            />
            <div className="task-container">
                {isLoading ? (
                    <Spinner color="rgba(128, 128, 128, 0.5)" />
                ) : (
                    <div className="detail-task-container">
                        <button className="close-button" onClick={navigateToDashboard}>×</button>
                        <p><strong>Date:</strong> {new Date(task.date_created).toLocaleString()}</p>
                        <p><strong>Status:</strong> {task.status}</p>
                        <p>
                            <strong>Description:</strong>
                            <br />
                            <br />
                            {task.description}
                        </p>

                        <p>
                            <strong>Result:</strong>
                            <br />
                            <br />
                            {task.result}
                        </p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ButtonWithLoading
                                className="delete-button"
                                onClick={handleDelete}
                                loading={isLoading}
                                backgroundColor={backgroundColor}
                                hoverColor={hoverColor}
                                style={{ fontSize: '0.8rem' }}
                            >
                                Delete Task
                            </ButtonWithLoading>
                        </div>
                    </div>
                )}
            </div>
            {!isLoading && (task && ((task.scheduled_calls && task.scheduled_calls.length > 0) || (taskCalls.length > 0))) && (
                <div className="detail-calls-container">
                    {task.scheduled_calls && task.scheduled_calls.length > 0 && (
                        <>
                            <h3>Scheduled Calls</h3>
                            {task.scheduled_calls.map((call, index) => (
                                <div key={call.scheduled_call_id} className="call-item" onClick={() => toggleCallExpand(call.scheduled_call_id)}>
                                    <span>Call {index + 1}: </span>
                                    <span><strong>Scheduled</strong></span>
                                    {expandedCallID === call.scheduled_call_id && (
                                        <div>
                                            <p><strong>Date Scheduled:</strong> {new Date(call.callback_date_scheduled).toLocaleString()}</p>
                                            <p><strong>Phone Number:</strong> {call.outbound_phone_number}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                    {taskCalls.length > 0 && (
                        <>
                            <h3>Calls</h3>
                            {taskCalls.map((call, index) => (
                                <div key={call.call_id} className="call-item" onClick={() => toggleCallExpand(call.call_id)}>
                                    <span>Call {index + 1}: </span>
                                    {call.states.length > 0 && <span>
                                        <strong>{call.states[call.states.length - 1].state}</strong> - {new Date(call.states[call.states.length - 1].timestamp).toLocaleString()}
                                    </span>}
                                    {expandedCallID === call.call_id && (
                                        <div>
                                            <p><strong>Phone Number:</strong> {call.outbound_phone_number}</p>
                                            <p><strong>Outcome:</strong> {call.outcome}</p>

                                            <p><strong>States:</strong></p>
                                            {call.states.map((stateObj, stateIndex) => (
                                                <p key={stateIndex}>
                                                    <strong>- {stateObj.state}</strong> - {new Date(stateObj.timestamp).toLocaleString()}
                                                </p>
                                            ))}

                                            <div className="transcript">
                                                <h4>Transcript</h4>
                                                <div style={{ color: '#d3d3d3' }}>----------------------------------------------------------------</div>
                                                {call.transcript.map((transcriptObj, transcriptIndex) => (
                                                    <p key={transcriptIndex} className={transcriptObj.role === "user" ? "user-message" : "assistant-message"}>
                                                        <strong>- {transcriptObj.role === "user" ? "recipient" : "tagin"}</strong> - {transcriptObj.content}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default DetailTaskItem;
