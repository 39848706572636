import React, { createContext, useContext, useState, useEffect } from 'react';
import { initializeFirebaseApp } from '../firebase'; // Import your Firebase initialization function

// Create a Context for Firebase
const FirebaseContext = createContext(null);

// Create a provider component
export const FirebaseProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const url = window.location.href;
        const isDev = url.includes("/dev/");

        // Initialize Firebase only once with the correct configuration
        const { auth } = initializeFirebaseApp(isDev);
        setAuth(auth);
        setIsInitialized(true); // Mark Firebase as initialized
    }, []);

    return (
        <FirebaseContext.Provider value={{ auth, isInitialized }}>
            {children}
        </FirebaseContext.Provider>
    );
};

// Custom hook to use Firebase
export const useFirebase = () => {
    return useContext(FirebaseContext);
};
