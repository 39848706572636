import React, { useEffect, useState } from 'react';
import Spinner from '../../Spinners/CircleSpinner/CirlceSpinner';
import './BaseButton.css';

const ButtonWithLoading = ({
    onClick,
    children,
    loading,
    disabled,
    backgroundColor = '#4285F4',
    hoverColor = '#2563eb',
    textColor = '#ffffff',
    height = '48px',
}) => {
    const [isLoading, setIsLoading] = useState(loading);
    const [isHovered, setIsHovered] = useState(false); // Track hover state

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    const handleClick = async () => {
        if (!isLoading) {
            await onClick();
        }
    };

    return (
        <button
            className="base-button"
            onClick={() => { handleClick() }}
            onMouseEnter={() => setIsHovered(true)} // Hover state logic
            onMouseLeave={() => setIsHovered(false)}
            disabled={isLoading || disabled}
            style={{
                backgroundColor: isHovered ? hoverColor : backgroundColor,
                color: textColor,
                height: height,
                transition: 'background-color 0.3s ease', // Smooth transition for hover effect
            }}
        >
            {isLoading ? <Spinner /> : children}
        </button>
    );
};

export default ButtonWithLoading;
