import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import TaskList from '../Tasks/TaskList';
import Header from "../../components/Header/Header";
import { useRoute } from "../../utils/RouteContext";

const Dashboard = ({ baseURL }) => {
    const navigate = useNavigate();
    const { determineRoute, RouteEnum } = useRoute();
    const [cachedTasks, setCachedTasks] = useState([]);

    const newTaskClicked = (task_id = null) => {
        const route = determineRoute(RouteEnum.NEW_TASK);
        if (task_id) {
            navigate(`${route}/${task_id}`);
        } else {
            navigate(route);
        }
    };

    const inProgressTaskClicked = (task) => {
        if (task.status === "research" || task.status === "more_information_required") {
            newTaskClicked(task.task_id);
        } else {
            taskClicked(task.task_id);
        }
    };

    const taskClicked = (task_id) => {
        const route = determineRoute(RouteEnum.TASK);
        navigate(`${route}/${task_id}`);
    };

    return (
        <>
            <Header
                environment={baseURL?.includes("dev") ? "dev" : "prod"}
                respectUser={true}
                onNewTask={newTaskClicked}
            />

            <TaskList
                baseURL={baseURL}
                onInProgressTaskClick={inProgressTaskClicked}
                onTaskSelect={taskClicked}
            />
        </>
    );
};

export default Dashboard;
