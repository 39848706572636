import React, { createContext, useContext } from 'react';

const RouteEnum = {
    DASHBOARD: '/dashboard',
    LOGIN: '/login',
    HOME: '/',
    NEW_TASK: '/new-task',
    TASK: '/dashboard/task',
    PROFILE: '/profile',
    ONBOARDING: '/onboarding',
};

const RouteContext = createContext(null);

export const RouteProvider = ({ children }) => {
    const determineRoute = (route) => {
        const url = window.location.href;
        const basePath = url.includes("/dev/") ? '/dev' : '';
        const resolvedRoute = Object.values(RouteEnum).includes(route) ? route : '';
        return `${basePath}${resolvedRoute}`;
    };

    // Ensure that the context is not undefined
    if (!children) {
        console.error("RouteProvider must have children");
        return null;
    }

    return (
        <RouteContext.Provider value={{ determineRoute, RouteEnum }}>
            {children}
        </RouteContext.Provider>
    );
};

export const useRoute = () => {
    const context = useContext(RouteContext);
    if (!context) {
        throw new Error("useRoute must be used within a RouteProvider");
    }
    return context;
};