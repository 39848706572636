import React, { createContext, useContext, useState, useEffect } from 'react';

export const TaskCacheContext = createContext(null);

export const TaskCacheProvider = ({ children }) => {
    const [taskCache, setTaskCache] = useState(() => {
        const cachedTasks = localStorage.getItem('taskCache');
        return cachedTasks ? JSON.parse(cachedTasks) : {};
    });

    useEffect(() => {
        localStorage.setItem('taskCache', JSON.stringify(taskCache));
    }, [taskCache]);

    return (
        <TaskCacheContext.Provider value={{ taskCache, setTaskCache }}>
            {children}
        </TaskCacheContext.Provider>
    );
};

export const useTaskCache = () => {
    const context = useContext(TaskCacheContext);
    if (!context) {
        throw new Error("useTaskCache must be used within a TaskCacheProvider");
    }
    return context;
};