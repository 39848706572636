import "./PhoneNumberInput.css";

const PhoneNumberInput = ({ outboundPhoneNumber, setOutboundPhoneNumber }) => {

    const formatPhoneNumber = (value) => {
        if (!value) return value;
        // Remove all non-digits from the value
        const phoneNumber = value.replace(/[^\d]/g, "");

        // Format the phone number
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;

        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }

        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handlePhoneNumberChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setOutboundPhoneNumber(formattedPhoneNumber);
    };

    return (
        <div className="form-container">
            <label className="form-container-label">Outbound Phone Number (USA only)</label>
            <label className="form-container-sublabel">We only support USA numbers</label>
            <input
                className="form-container-input"
                value={outboundPhoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="(XXX) XXX-XXXX"
            />
        </div>
    )
}

export default PhoneNumberInput;