import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import Intercom from '@intercom/messenger-js-sdk';

import PageNotFoundView from './PageNotFoundView';
import trackPathForAnalytics from './GAAnalytics';
import { ApiProvider } from './utils/apiContext';
import Dashboard from './screens/Dashboard/Dashboard';
import GoogleSignIn from './screens/GoogleSignIn/GoogleSignIn';
import ProtectedRoute from './utils/ProtectedRoute';
import { initializeFirebaseApp } from './firebase';
import { FirebaseProvider } from './utils/FirebaseContext';
import { AuthProvider } from './utils/AuthContext';
import './index.css';
import { NotificationProvider } from './components/Alert/Notification/NotificationContext';
import LandingPage from './screens/LandingPage/LandingPage';
import RedeemInviteCode from './screens/RedeemInviteCode/RedeemInviteCode';
import { RouteProvider } from './utils/RouteContext';
import NewTaskPage from './screens/Dashboard/NewTaskPage';
import BlogPost from './screens/BlogPost/BlogPost';
import EditProfile from './screens/Profile/Profile';
import Onboarding from './screens/Onboarding/Onboarding';
import DetailTaskItem from './screens/Tasks/DetailTaskItem';
import { TaskCacheProvider } from './screens/Tasks/TaskCacheContext';

function App() {
  const [baseURL, setBaseURL] = useState(null);
  const { pathname } = useLocation();


  const updateBaseURLAndFirebase = () => {
    const url = window.location.href;
    if (url.includes("/dev/")) {
      setBaseURL("https://dev.tagin.ai");
      initializeFirebaseApp(true);
      console.log("URL: ", url);
    } else {
      setBaseURL("https://prod.tagin.ai");
      initializeFirebaseApp(false);
    }
  };

  useEffect(() => {
    updateBaseURLAndFirebase();
  }, [pathname]); // Re-run when pathname changes

  const analytics = useCallback(() => {
    trackPathForAnalytics({ path: pathname, search: window.location.search, title: pathname.split("/")[1] });
  }, [pathname]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  Intercom({
    app_id: 'fo4cip1h'
  });

  return (

    <RouteProvider>
      <FirebaseProvider>
        <AuthProvider>
          <ApiProvider baseURL={baseURL}>
            <NotificationProvider>
              <TaskCacheProvider>
                <div className="content">
                  <Routes>
                    <Route path="/" element={<LandingPage baseURL={baseURL} />} />
                    <Route path="/dev" element={<LandingPage baseURL={baseURL} />} />
                    <Route path="/blog-post/1" element={<BlogPost baseURL={baseURL} />} />
                    <Route path="/login" element={<GoogleSignIn baseURL={baseURL} />} />
                    <Route path="/dev/login" element={<GoogleSignIn baseURL={baseURL} />} />
                    <Route path="/redeem" element={<RedeemInviteCode baseURL={baseURL} />} />
                    <Route path="/dev/redeem" element={<RedeemInviteCode baseURL={baseURL} />} />
                    <Route path="/dashboard" element={
                      <ProtectedRoute>
                        <Dashboard baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/dev/dashboard" element={
                      <ProtectedRoute>
                        <Dashboard baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/dashboard/task/:task_id" element={
                      <ProtectedRoute>
                        <DetailTaskItem baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/dev/dashboard/task/:task_id" element={
                      <ProtectedRoute>
                        <DetailTaskItem baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/new-task" element={
                      <ProtectedRoute>
                        <NewTaskPage baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/dev/new-task" element={
                      <ProtectedRoute>
                        <NewTaskPage baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/new-task/:task_id" element={
                      <ProtectedRoute>
                        <NewTaskPage baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/dev/new-task/:task_id" element={
                      <ProtectedRoute>
                        <NewTaskPage baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/profile" element={
                      <ProtectedRoute>
                        <EditProfile baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/dev/profile" element={
                      <ProtectedRoute>
                        <EditProfile baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/onboarding" element={
                      <ProtectedRoute>
                        <Onboarding baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/dev/onboarding" element={
                      <ProtectedRoute>
                        <Onboarding baseURL={baseURL} />
                      </ProtectedRoute>
                    } />
                    <Route path="/no-page-found" element={<PageNotFoundView baseURL={baseURL} />} />
                    <Route path="/dev/no-page-found" element={<PageNotFoundView baseURL={baseURL} />} />
                    <Route path="*" element={<PageNotFoundView baseURL={baseURL} />} />
                  </Routes>
                </div>
              </TaskCacheProvider>
            </NotificationProvider>
          </ApiProvider>
        </AuthProvider>
      </FirebaseProvider>
    </RouteProvider>
  );
}

export default App;