import React from 'react';
import './HowItWorksInfoCard.css';

const HowItWorksInfoCard = ({ icon, title, description }) => {
    return (
        <div className="info-card">
            <img src={icon} alt={title} className="card-icon" />
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    );
};

export default HowItWorksInfoCard;