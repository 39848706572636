import React from 'react';
import './TaskItem.css';

const TaskItem = ({ task, didSelect }) => {
    // Function to calculate "time ago"
    function timeAgo(timestamp) {
        const now = new Date();
        const date = new Date(timestamp);
        const secondsAgo = Math.floor((now - date) / 1000);

        let interval = Math.floor(secondsAgo / 31536000);
        if (interval >= 1) return interval === 1 ? `${interval} year ago` : `${interval} years ago`;

        interval = Math.floor(secondsAgo / 2592000);
        if (interval >= 1) return interval === 1 ? `${interval} month ago` : `${interval} months ago`;

        interval = Math.floor(secondsAgo / 86400);
        if (interval >= 1) return interval === 1 ? `${interval} day ago` : `${interval} days ago`;

        interval = Math.floor(secondsAgo / 3600);
        if (interval >= 1) return interval === 1 ? `${interval} hour ago` : `${interval} hours ago`;

        interval = Math.floor(secondsAgo / 60);
        if (interval >= 1) return interval === 1 ? `${interval} minute ago` : `${interval} minutes ago`;

        return secondsAgo === 1 ? `${secondsAgo} second ago` : `${secondsAgo} seconds ago`;
    }

    const timeAgoString = timeAgo(task.date_created);

    const researchStyleStatus = (status) => {
        switch (status) {
            case 'research':
                return 'RESEARCH';
            case 'queued':
                return 'QUEUED';
            case 'in_progress':
                return 'IN PROGRESS';
            case 'call_back_same_number':
                return 'CALL BACK SAME NUMBER';
            case 'call_back_different_number':
                return 'CALL BACK DIFFERENT NUMBER';
            case 'completed':
                return 'COMPLETED';
            case 'more_information_required':
                return 'MORE INFORMATION REQUIRED';
            case 'in_progress_scheduled_call':
                return 'SCHEDULED CALL(S)';
            default:
                return status;
        }
    };

    return (
        <div className={`task-item`} onClick={didSelect}>
            <div className="task-info">
                <label className="task-date">{timeAgoString}</label>
                <label className="task-title">{task.title}</label>
                <label className="task-description">{task.description}</label>
            </div>
            <label className={`task-status-${task.status}`}>{researchStyleStatus(task.status)}</label>
        </div>
    );
};

export default TaskItem;