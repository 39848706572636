import React, { useEffect, useState } from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../../utils/FirebaseContext';
import './GoogleSignIn.css';
import { useAuth } from '../../utils/AuthContext';
import { useNotifications } from '../../components/Alert/Notification/NotificationContext';
import ButtonWithLoading from '../../components/Buttons/GeneralButton/BaseButton';
import Header from '../../components/Header/Header';
import { useRoute } from '../../utils/RouteContext';


const GoogleSignIn = ({ baseURL }) => {
    const navigate = useNavigate();
    const { determineRoute, RouteEnum } = useRoute();
    const { user } = useAuth(); // Get the user from AuthContext
    const { auth } = useFirebase(); // Access the auth object from the context
    const [loading, setLoading] = useState(true); // Start loading as true
    const { addNotification } = useNotifications();
    const [makingRequest, setMakingRequest] = useState(false);

    useEffect(() => {
        if (makingRequest === true) {
            console.log('MAKING REUQEST');
            return;
        }

        if (user) {
            const path = determineRoute(RouteEnum.DASHBOARD);
            navigate(path);
        } else {
            setLoading(false);
        }
    }, [user, navigate, makingRequest]);

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();

        try {
            setMakingRequest(true);
            // Sign in with Firebase and get the Firebase ID token
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Get the Firebase ID token
            const idToken = await user.getIdToken();

            setLoading(true);

            // Send the Firebase ID token to your backend for verification
            const response = await fetch(`${baseURL}/auth/google`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: idToken }),
            });

            if (response.ok) {
                const data = await response.json();
                // Handle successful login, store session token, navigate to dashboard
                localStorage.setItem('sessionToken', idToken);
                const path = determineRoute((data.new_user && data.new_user === true) ? RouteEnum.ONBOARDING : RouteEnum.DASHBOARD);
                navigate(path);
            } else {
                addNotification('Failed to authenticate', 'error'); // Use addNotification for error
            }
            setLoading(false);
            setMakingRequest(false);
        } catch (error) {
            if (error.code === 'auth/popup-closed-by-user') {
                console.warn('Google Sign-In popup was closed by the user.');
            } else {
                addNotification('Google Sign-In failed: ' + error.message, 'error'); // Use addNotification for error
            }
            setLoading(false);
            setMakingRequest(false);
        }
    };

    return (
        <>
            <Header environment={baseURL?.includes('dev') ? 'dev' : 'prod'} respectUser={true} logoOnly={true} />
            <div className="google-login-page">
                <ButtonWithLoading
                    onClick={handleGoogleSignIn}
                    loading={loading}
                >
                    Sign in with Google
                </ButtonWithLoading>
            </div>
        </>
    );
};

export default GoogleSignIn;
